import * as React from "react"

import 'bootstrap/dist/css/bootstrap.min.css';
import Seo from "../components/seo"
import Header from "../components/header"
import Footer from "../components/footer"


const ThanksPage = () => (
  <>
  <Seo
title="THANKS!お申し込み完了"
description="お申し込み完了ページ。ランディングページ作成サービス。テンプレートでの制作や様々なジャンルのLPの短納期・格安制作。またデザインのみ、コーディングのみの案件も相場より安い料金で承っているのでおすすめです。LPOのリスティング運用代行も展開しております。"
/>
  <Header />
  <div className="container py-5">
  <div className="py-5 text-center">
  <h1 className="display-3">Thank you!</h1>
  <p className="lead">お問い合わせありがとうございました。<br />担当より<strong>24時間以内(土日祝を除く)</strong>にご連絡いたします。</p>
  <hr />

  <p className="lead">
    <a className="btn btn-primary btn-sm" href="https://bgreen.co.jp/" role="button">Bgreen公式サイト</a>
  </p>
</div>
</div>
  <Footer />
  </>
)

export default ThanksPage
